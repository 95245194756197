/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { TopHeader, TopHeaderWrap, Generic, Title, Text, SeparateLine, SeparateLineWrap, SeparateLineElement, Column, ColumnWrap, ColumnWrapper, Subtitle, Button, Image, Form } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <TopHeader className="top-header top-header--invert pb--12 pt--12" name={"s09mhwymkjj"} style={{"backgroundColor":"rgba(10, 123, 248, 1)"}}>
          
          <TopHeaderWrap className="top-header__wrap">
            
            <Generic TagName={"div"} RootClassName={"top-header__logo-box"}>
              
              <Title className="top-header__logo title-box fs--16" content={"<span style=\"color: rgb(255, 255, 255);\">MajLonMarin s.r.o<br></span>IČO:24198919 DIČ:CZ24198919<br>"}>
              </Title>

            </Generic>

            <Generic TagName={"div"} RootClassName={"top-header__contact-box"}>
              
              <Generic TagName={"div"} RootClassName={"top-header__contact"}>
                
                <Text className="top-header__adress text-box text-box--center text-box--invert" href={""} content={"Jihlavská 606/8<br>140 00 Praha 4-Michle<br>"}>
                </Text>

                <Text className="top-header__phone text-box text-box--invert" href={""} content={""}>
                </Text>

              </Generic>

            </Generic>

          </TopHeaderWrap>

        </TopHeader>


        <SeparateLine className="pb--10" name={"jt5z567e3lk"} style={{"paddingTop":14}}>
          
          <SeparateLineWrap >
            
            <SeparateLineElement >
            </SeparateLineElement>

          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--style2 --full" name={"htxyv91nc35"} style={{"backgroundColor":"rgba(51, 16, 211, 1)"}} fullscreen={true} backgroundPosition={"center"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":460}}>
              
              <Subtitle className="subtitle-box fs--16 w--300 subtitle-box--invert" style={{"color":"rgba(238,44,44,1)","paddingTop":6.21875}} content={"<span style=\"font-weight: bold; color: rgb(210, 218, 32);\">NECHTE TO NA NÁS A BUDETE SPOKOJENÍ</span>"}>
              </Subtitle>

              <Title className="title-box w--900 title-box--invert" content={"stavební&nbsp; a úklidové práce<br>MajLonMarin s.r.o<br>"}>
              </Title>

              <Text className="text-box text-box--invert" style={{"maxWidth":320}} content={"Zabýváme se širokým spektrem stavebních a ú klidových úkonů s výbornými výsledky"}>
              </Text>

              <Button className="btn-box btn-box--style2" href={"#1pt3ajgyvut"} style={{"backgroundColor":"rgba(255, 85, 10, 1)"}} content={"DOZVĚDĚT SE VÍCE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"xkev1b27u9m"} layout={"l3"}>
          
          <SeparateLineWrap >
            
            <SeparateLineElement >
            </SeparateLineElement>

          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--40" name={"7v0p1l16xk"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"paddingBottom":0}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"POSKYTOVANÉ SLUŽBY PRO VÁS"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"2sv6hk92rhl"} layout={"l4"}>
          
          <SeparateLineWrap >
            
            <SeparateLineElement >
            </SeparateLineElement>

          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--30" name={"1pt3ajgyvut"} style={{"backgroundColor":"rgba(80, 227, 194, 1)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/o/2469/cc1267af33f54318958cc945c24f8c81.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"Rekonstrukce bytů a kanceláří"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Provádíme kompletní přestavby bytů i bytového jádra stejně tak i kanceláří dle požadavků zákazníka<br><br>"}>
              </Text>

              <Button className="btn-box btn-box--style2" href={"#ae9nx4s38do"} content={"MÁM ZÁJEM"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/o/2469/47cf3cfabd474303b66a02fea3e4b93d.png"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"výstavby domů"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Provádíme výstavbu domů od základu po hrubou stavbu a dále práce spojené pro zednictví<br><br><br>"}>
              </Text>

              <Button className="btn-box btn-box--style2" href={"#ae9nx4s38do"} content={"MÁM ZÁJEM"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2469/be257d329d0f420e9a4c2b64b9510d7c_s=660x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2469/be257d329d0f420e9a4c2b64b9510d7c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2469/be257d329d0f420e9a4c2b64b9510d7c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2469/be257d329d0f420e9a4c2b64b9510d7c_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"zbudování základové desky"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Zajišťujeme výkopové práce ,přípravy a výstavbu základovyh desek libovolných rozměrů<br><br>"}>
              </Text>

              <Button className="btn-box btn-box--style2" href={"#ae9nx4s38do"} content={"MÁM ZÁJEM"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2469/a1003c6dbd0c43ecb2924cb097da4461_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2469/a1003c6dbd0c43ecb2924cb097da4461_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2469/a1003c6dbd0c43ecb2924cb097da4461_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2469/a1003c6dbd0c43ecb2924cb097da4461_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2469/a1003c6dbd0c43ecb2924cb097da4461_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2469/a1003c6dbd0c43ecb2924cb097da4461_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"Úklidové práce"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"zajišťujeme kompletní úklidy na stavbách,probíhajících rekostrukcí, tak i úklidy kanceláří,bytů , domů a firemních prostorů"}>
              </Text>

              <Button className="btn-box btn-box--style2" href={"#ae9nx4s38do"} content={"MÁM ZÁJEM"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--40" name={"ae9nx4s38do"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"Kontaktujte nás"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="A_disalow-edit --center">
              
              <Form action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </Form>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"rjv080r4mxr"} style={{"backgroundColor":"var(--color-variable-2)"}}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--18 subtitle-box--invert" content={"Otevírací doba"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--10" content={"Po - Pa   8:00 - 16:00"}>
              </Text>

              <Text className="text-box text-box--invert mt--02 pt--0" content={"So          8:00 - 12:00"}>
              </Text>

              <Text className="text-box text-box--invert mt--02 pt--0" content={"Ne         ZAVŘENO"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box fs--18 subtitle-box--invert" content={"Kontakt"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--10" content={"Tel.: +420 773 229 552"}>
              </Text>

              <Text className="text-box text-box--invert lh--16 mt--02" content={"Email: vasemail@vasedomena.cz"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Subtitle className="subtitle-box fs--18 subtitle-box--invert" content={"autoservis.saywebpage.com"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--10" content={"Vytvořeno přes <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}